.flow-chart-container {
  padding: 20px;
  overflow: hidden;
}

.flow-chart-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.subtitle{
  font-size: 36px;
  color: rgb(255, 215, 0);
}


.flow-item {
  position: relative;
  flex: 1;
  min-width: 120px;
  text-align: center;
  padding: 0 15px;
}

.icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
  position: relative;
  z-index: 2;
}

.icon-circle span {
  color: #c08f55;
  font-size: 32px;
  font-weight: bold;
}

.connector {
  position: absolute;
  top: 30px;
  left: calc(50% + 30px);
  right: calc(-50% + 30px);
  height: 1px;
  background-color: hsla(0, 0%, 100%, 0.1);
  z-index: 1;
}

@media (max-width: 767px) {
  .flow-chart-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .flow-item {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .connector {
    display: none;
  }
}