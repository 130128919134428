/* App.css */


.stats {
  /* background-color: black; */
  color: white;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .stats {
    flex-direction: row;
    justify-content: space-around;
  }
}

.stat-item {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 10px;
}

.number {
  font-size: 3rem;
  font-weight: bold;
}

.plus {
  color: red;
  font-size: 2.5rem;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.labels span {
  display: inline-block;
  width: 100%;
  display: block;
  color: #acacac;
  text-align: left;
}
