.comparison-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
}

.negative, .positive {
  flex: 1;
  display: flex;
  align-items: center;
}

.negative {
  justify-content: flex-end;
  text-align: right;
}

.positive {
  justify-content: flex-start;
  text-align: left;
}

.icon {
  font-size: 24px;
  margin: 0 10px;
}

.separator {
  margin: 0 15px;
  font-weight: bold;
  color: #888;
}

.text {
  flex: 1;
  text-align: left;
}

@media (max-width: 768px) {
  .comparison-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .negative, .positive {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .separator {
    display: none;
  }
}